export function once(fn: (event: MouseEvent | TouchEvent) => any) {
	return function (event: MouseEvent | TouchEvent) {
		if (fn) {
			const result = fn.call(this, event)
			fn = null
			return result
		}
	}
}

export function preventDefault(fn: (event: MouseEvent | TouchEvent) => any) {
	return function (event: MouseEvent | TouchEvent) {
		event.preventDefault()
		return fn.call(this, event)
	}
}

export function stopPropagation(fn: (event: MouseEvent | TouchEvent) => any) {
	return function (event: MouseEvent | TouchEvent) {
		event.stopPropagation()
		return fn.call(this, event)
	}
}
